// check for valid email
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getActivePage = () => {
  return window.location.pathname;
};

export const isProd = process.env.NODE_ENV === "production";

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};
export const secondsToHms = (seconds) => {
  seconds = Number(seconds);
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " : " : " : ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " : " : " : ") : "0 : ";
  var sDisplay = s > 0 ? s + (s === 1 ? " " : " ") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const updateURLQuery = (history, location, paramName, paramValue) => {
  let urlQuery = new URLSearchParams(location.search);
  urlQuery.set(paramName, paramValue);
  history.replace({
    search: urlQuery.toString(),
  });
};

export const removeURLQuery = (history, location, paramName) => {
  let urlQuery = new URLSearchParams(location.search);
  urlQuery.delete(paramName);
  history.replace({
    search: urlQuery.toString(),
  });
};

export const getURLParam = (newUrlParam = [], location = null) => {
  let urlQuery = new URLSearchParams(location ? location.search : "");
  if (newUrlParam.length > 0) {
    newUrlParam.forEach((param) => {
      urlQuery.set(param.name, param.value);
    });
  }
  return urlQuery.toString();
};

export const redirectClinet = (
  history,
  location,
  newPath,
  newUrlParam = [],
  removeUrlParam = [],
  hash = null
) => {
  let urlQuery = new URLSearchParams(location.search);
  if (newUrlParam.length > 0) {
    newUrlParam.forEach((param) => {
      urlQuery.set(param.name, param.value);
    });
  }
  if (removeUrlParam.length > 0) {
    removeUrlParam.forEach((param) => {
      urlQuery.delete(param);
    });
  }
  let locationProp = {
    pathname: newPath,
    search: urlQuery.toString(),
  };
  if (hash) {
    locationProp = {
      ...locationProp,
      hash,
    };
  }
  history.push(locationProp);
};

/* Get the documentElement (<html>) to display the page in fullscreen */
var elem = document.documentElement;

/* View in fullscreen */
export const openFullscreen = () => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};
/* Close fullscreen */
export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
};

// scroll into view with offset
export const scrollToTargetAdjusted = (id, offsetY) => {
  var element = document.getElementById(id);
  var headerOffset = offsetY;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const scrollToBottom = (id) => {
  var objDiv = document.getElementById(id);
  objDiv.scrollTop = objDiv.scrollHeight;
};

// trim string at certain length
export const trimString = (string, limit) => {
  if (string.length <= limit) {
    return string;
  }
  return string.slice(0, limit) + "...";
};

//load a image
export const loadImageAsync = (imgURL) => {
  return new Promise(async (res, rej) => {
    try {
      let img = new Image();
      img.onload = () => res();
      img.onerror = (err) => rej(err);
      img.src = imgURL;
    } catch (error) {
      rej(error);
    }
  });
};

export const windowOpenUrl = (url) => {
  window.open(url);
};
