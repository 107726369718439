import React, { useRef } from "react";
import { MANKIND_STANDALONE_THEME } from "../../AppConstants/ThemeConstants";
import MuxVideoPlayer from "../MuxVideoPlayer";
import "./index.css";

const Modal = ({ setShowModal, videoData }) => {
  const muxVideoRef = useRef("");

  const handleNoteBtn = () => {
    console.log("first");
  };

  const closeStartWatchingBtn = () => {
    console.log("first");
  };
  const handleVideoLoading = () => {
    console.log("first");
  };
  return (
    <div className="modalWrapper">
      <div onClick={() => setShowModal(false)} className="modalOverlay"></div>

      <div className="modal">
        <div onClick={() => setShowModal(false)} className="closeBtn">
          Close
        </div>
        <MuxVideoPlayer
          showPlayer={true}
          isSidePanelOpen={false}
          handleNoteBtn={handleNoteBtn}
          startingPoint={0}
          closeStartWatchingBtn={closeStartWatchingBtn}
          uid={null}
          videoData={videoData}
          muxVideoRef={muxVideoRef}
          themeType={MANKIND_STANDALONE_THEME}
          handleVideoLoading={handleVideoLoading}
          allNotes={[]}
        />
      </div>
    </div>
  );
};

export default Modal;
