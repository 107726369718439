import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDmIS_9sD8CLgliSB-SbCxlD20MidfJmnY",
  authDomain: "mankindconnect.firebaseapp.com",
  databaseURL:
    "https://mankindconnect-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mankindconnect",
  storageBucket: "mankindconnect.appspot.com",
  messagingSenderId: "717329083275",
  appId: "1:717329083275:web:be3332dc3bb6bc30ea9d8e",
  measurementId: "G-LSP6QERS0M",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

export default app;
// export const firestore = firebase.firestore();
// export const database = firebase.database();
// export const functions = firebase.functions();
// export const storage = firebase.storage();
// cloudFunction_as.useEmulator('localhost', 5001);
