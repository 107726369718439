import { memo, useContext, useEffect, useState, useRef } from "react";
import MuxVideo from "@mux/mux-video-react";
import "media-chrome";
// import "media-chrome/dist/extras/media-clip-selector";

import playerTheme from "./MuxPlayer.module.scss";

import {
  EVENT_THEME,
  VIDEO_THEME,
  MANKIND_STANDALONE_THEME,
} from "../../AppConstants/ThemeConstants";
import { trimString } from "../../utils/common.utils";
import { VideoPlayerContext } from "../../Context/VideoContextProvider";

const attributes = {
  autohide: 2,
  autoplay: false,
  muted: false,
  streamType: "on-demand", //"on-demand", "live" (HLS live stream), "ll-live" (low latency live)
};
const envKey = "m69hm2j1u4va5d19h9abm0aco";
// !isProd
//   ? "ou7u7a38cm4h875fk5tfe6h9l"
//   : "kkbh10tcou5aqhiepr38hkjvm";

const MuxVideoPlayer = ({
  isSidePanelOpen,
  handleNoteBtn,
  closeStartWatchingBtn,
  handleVideoLoading,
  startingPoint,
  uid,
  videoData,
  showPlayer,
  muxVideoRef,
  themeType,
  allNotes,
}) => {
  const userId = uid ? uid : "anonymous";
  const userid = uid ? uid : null;

  const { isFullScreen, handleFullScreen } = useContext(VideoPlayerContext);

  const [bookMark, setBookMark] = useState({});
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [data, setData] = useState({
    playbackid: videoData.playback_ids[0].id,
    title: videoData.title,
    tokens: videoData.tokens ? videoData.tokens : {},
  });
  const videoScoreGiven = useRef({ id: videoData.id, scored: false });
  const initialPlay = useRef(false);
  // Positions of markers in seconds.
  const [positions, setPositions] = useState(
    (allNotes && allNotes.content) || []
  );
  const [showBookmarkBar, setShowBookmarkBar] = useState(false);
  const [theme, setTheme] = useState(themeType);

  const mediaClipSelect = document.querySelector("media-clip-selector");

  useEffect(() => {
    if (muxVideoRef.current) {
      // if (theme === VIDEO_THEME) {
      //   muxVideoRef.current.currentTime = startingPoint;
      // }

      muxVideoRef.current.addEventListener("loadedmetadata", (e) => {
        if (theme === EVENT_THEME) {
          muxVideoRef.current.muted = false;
        }
        if (theme === VIDEO_THEME) {
          addMarkers();
        }
        handleVideoLoading(false);
      });

      muxVideoRef.current.addEventListener("play", async (e) => {
        if (theme === EVENT_THEME) {
          if (!muxVideoRef.current.paused) {
            muxVideoRef.current.currentTime = muxVideoRef.current.duration;
          }
        } else {
          if (!initialPlay.current) {
            muxVideoRef.current.currentTime = startingPoint;
            initialPlay.current = true;
          }

          // try {
          //   await marketingMetaData.addUncompleteVideo(
          //     videoData,
          //     userid,
          //     userBasicDetails,
          //     userMetadata,
          //     {
          //       lastWatchTimestamp: muxVideoRef.current.currentTime,
          //     }
          //   );
          // } catch (err) {
          //   console.error(err);
          // }
        }
      });
      muxVideoRef.current.addEventListener("ended", async (e) => {
        if (theme === VIDEO_THEME) {
          updateScoreAndTime();
          // try {
          //   await marketingMetaData.addCompleteVideo(
          //     videoData,
          //     userid,
          //     userBasicDetails,
          //     userMetadata,
          //     {
          //       lastWatchTimestamp: muxVideoRef.current.currentTime,
          //     }
          //   );
          // } catch (err) {
          //   console.error(err);
          // }
        }
      });

      muxVideoRef.current.addEventListener("pause", (e) => {
        if (theme === VIDEO_THEME) {
          updateScoreAndTime();
        }
      });

      muxVideoRef.current.addEventListener("timeupdate", () => {
        setCurrentVideoTime(muxVideoRef.current.currentTime);
        // setCurrentVideoTime(Math.round(muxVideoRef.current.currentTime));
      });
    }

    // playbtn UI updation

    // let playbtn = document.querySelector("media-play-button");
    // if (playbtn && playbtn.shadowRoot) {
    //   console.log("DS", playbtn.shadowRoot.querySelector("slot[name=play]"));
    //   console.log("DS", playbtn.shadowRoot.querySelector("slot[name=pause]"));
    // }

    if (mediaClipSelect && mediaClipSelect.shadowRoot) {
      mediaClipSelect.addEventListener("update", (evt) => {
        const { startTime, endTime } = evt.detail;
        setBookMark({ start: startTime, end: endTime });
      });

      // overide time frame css
      mediaClipSelect.shadowRoot
        .querySelector("#thumbnailContainer")
        .setAttribute("style", "top:unset");
      mediaClipSelect.shadowRoot
        .querySelector("#selectorContainer")
        .querySelector("#selection")
        .querySelector("#startHandle")
        .setAttribute("style", "background-color:#eb4547");

      mediaClipSelect.shadowRoot
        .querySelector("#selectorContainer")
        .querySelector("#selection")
        .querySelector("#spacer")
        .setAttribute("style", "background-color:#eb4547");

      mediaClipSelect.shadowRoot
        .querySelector("#selectorContainer")
        .querySelector("#selection")
        .querySelector("#endHandle")
        .setAttribute("style", "background-color:#eb4547");
    }

    // return () =>
    //   muxVideoRef.current && muxVideoRef.current.removeEventListener("loadedmetadata");
    return () => updateScoreAndTime();
  }, []);

  useEffect(() => {
    // Set the markers when we CAN know the duration of the video.
    if (muxVideoRef && allNotes && allNotes.content && theme === VIDEO_THEME) {
      setPositions(allNotes.content);
      addMarkers();
    }
  }, [muxVideoRef, allNotes]);

  const addMarkers = (pos) => {
    // Create some CSS to apply to the shadow dom
    let style = document.createElement("style");

    style.textContent = `
    .bubbles {
      background-color: #ffed4f !important;
      position: absolute;
      height: 5px;
      width: 7px;
      top: 50%;
      cursor: pointer;
      transform: translateY(-50%);
    }
    .bubbles:after {
      display: none;
      content: attr(data-content);
      position: absolute;
      bottom: 300%;
    }
    .bubbles:hover:after {
      display: block;
    }`;

    // seekbar
    const seekBar = document.querySelectorAll("media-time-range")[0].shadowRoot;
    seekBar.prepend(style);

    // Add each marker to the #seekbar element.
    positions.forEach((position) => {
      // Is position within range of the duration?
      if (position.startTime <= muxVideoRef.current.duration) {
        // Calculate position in percentage..
        const left =
          (position.startTime / muxVideoRef.current.duration) * 100 + "%";
        // ..create marker and give it the left value..
        const marker = document.createElement("div");
        marker.setAttribute("data-content", trimString(position.note, 50));
        marker.classList.add("bubbles");
        marker.style.left = left;

        marker.onclick = () =>
          (muxVideoRef.current.currentTime = position.startTime);
        // ..and add the marker to the #seekbar.
        seekBar.appendChild(marker);
      }
    });
  };

  const togglePause = (e) => {
    if (!muxVideoRef.current.paused) {
      muxVideoRef.current.pause();
    } else {
      muxVideoRef.current.play();
    }
  };

  const handleBookMarkButton = () => {
    setShowBookmarkBar((prev) => !prev);
  };

  const addVideoScore = () => {
    if (muxVideoRef.current && videoScoreGiven.current) {
      const { id, scored } = videoScoreGiven.current;
      if (id === videoData.id) {
        let percentage =
          (muxVideoRef.current.currentTime / muxVideoRef.current.duration) *
          100;

        if (Math.floor(percentage) >= 70 && theme === VIDEO_THEME && !scored) {
          videoScoreGiven.current.scored = true;

          // ScoreManager.watchedContent(videoData.id, userId);
        } else {
        }
      }
    }
  };
  const handleVideoBackBtn = () => {
    // close full Screen
    isFullScreen && handleFullScreen();
    if (muxVideoRef.current) {
      closeStartWatchingBtn(
        muxVideoRef.current.duration,
        muxVideoRef.current.currentTime,
        "backbtn"
      );
    } else {
      closeStartWatchingBtn();
    }
    if (theme === VIDEO_THEME) {
      addVideoScore();
    }
  };

  const updateScoreAndTime = () => {
    closeStartWatchingBtn(
      muxVideoRef.current.duration,
      muxVideoRef.current.currentTime,
      "pausebtn"
    );
    addVideoScore();
  };
  const handleFullScreenButton = () => {
    muxVideoRef.current.webkitEnterFullscreen();
  };

  return (
    <media-controller
      autohide={attributes.autohide}
      class={`${playerTheme["media-theme-docflix"]} ${
        showPlayer && "d-block"
      } ${
        !muxVideoRef.current.paused
          ? playerTheme["showplayer"]
          : playerTheme["hideplayer"]
      }`}
    >
      <MuxVideo
        ref={muxVideoRef}
        playbackId={`${data.playbackid}${
          data.tokens && data.tokens.videoToken && data.tokens.videoToken !== ""
            ? `?token=${data.tokens.videoToken}`
            : ``
        }`}
        // src={`https://stream.mux.com/${data.playbackid}.m3u8${
        //   data.tokens && data.tokens.videoToken && data.tokens.videoToken !== ""
        //     ? `?token=${data.tokens.videoToken}`
        //     : ""
        // }`}
        envKey={envKey}
        metadata={{
          video_id: data.id,
          video_title: data.title,
          viewer_user_id: userId,
        }}
        streamType={theme === EVENT_THEME ? "live" : attributes.streamType}
        slot="media"
        poster={`https://image.mux.com/${data.playbackid}/thumbnail.jpg${
          data.tokens &&
          data.tokens.thumbnailToken &&
          data.tokens.thumbnailToken !== ""
            ? `?token=${data.tokens.thumbnailToken}`
            : ``
        }`}
        preload="auto"
        crossOrigin="true"
        playsInline={true}
        autoPlay={theme === EVENT_THEME ? true : false}
        muted={theme === EVENT_THEME ? true : false}
        type="hls"
      >
        <track
          label="thumbnails"
          default
          crossOrigin="true"
          kind="metadata"
          src={`https://image.mux.com/${data.playbackid}/storyboard.vtt${
            data.tokens &&
            data.tokens.storyBoardToken &&
            data.tokens.storyBoardToken !== ""
              ? `?token=${data.tokens.storyBoardToken}`
              : ``
          }`}
        />
        {/* <track label="English" srcLang="en-us" kind="captions" src="" /> */}
      </MuxVideo>
      {/* {theme === VIDEO_THEME && !isMobileDevice && (
        <img
          onClick={handleVideoBackBtn}
          className={playerTheme["backbtn"]}
          src={backBtnImg}
          alt="back"
        />
      )} */}
      {theme === VIDEO_THEME || theme === MANKIND_STANDALONE_THEME ? (
        <div className="overlay" slot="centered-chrome">
          <media-seek-backward-button
            seek-offset="10"
            class={`${playerTheme["seekOverlay"]}`}
          ></media-seek-backward-button>
          <media-play-button
            class={`${playerTheme["seekOverlay"]}`}
            style={{
              borderRadius:
                theme === MANKIND_STANDALONE_THEME
                  ? "50%"
                  : // : theme === VIDEO_THEME
                    // ? "0 50% 50% 0"
                    "",
              background:
                theme === MANKIND_STANDALONE_THEME
                  ? "rgba(0, 0, 0, 0.3)"
                  : // : theme === VIDEO_THEME
                    // ? "#eb4547"
                    "",
            }}
          ></media-play-button>
          <media-seek-forward-button
            seek-offset="30"
            class={`${playerTheme["seekOverlay"]}`}
          ></media-seek-forward-button>
        </div>
      ) : null}
      {theme === EVENT_THEME && (
        <div className="overlay" slot="centered-chrome">
          <media-play-button
            // onClick={handleEventPlayBtn}
            class={`${playerTheme["seekOverlay"]}`}
            style={{
              borderRadius:
                theme === MANKIND_STANDALONE_THEME
                  ? "50%"
                  : // : theme === VIDEO_THEME
                    // ? "0 50% 50% 0"
                    "",
              background:
                theme === MANKIND_STANDALONE_THEME
                  ? "rgba(0, 0, 0, 0.3)"
                  : // : theme === VIDEO_THEME
                    // ? "#eb4547"
                    "",
            }}
          ></media-play-button>
        </div>
      )}

      {theme === VIDEO_THEME ? (
        <media-control-bar class="desktop">
          <media-clip-selector
            class={`${showBookmarkBar ? "" : "d-none"}`}
          ></media-clip-selector>
          <>
            <media-time-range
              class={`${!showBookmarkBar ? "" : "d-none"}`}
            ></media-time-range>
            <media-time-display
              show-duration
              // remaining
              class={`${!showBookmarkBar ? "" : "d-none"}`}
            ></media-time-display>
          </>
        </media-control-bar>
      ) : null}
      {theme === VIDEO_THEME ? (
        <>
          <media-control-bar
            class="desktop"
            style={{ background: "linear-gradient(180deg, transparent, #000)" }}
          >
            {/* slot="top-chrome" className="mobile" slot="top-chrome" */}
            <media-play-button></media-play-button>
            <media-mute-button></media-mute-button>
            <media-volume-range></media-volume-range>
            <span className={playerTheme["control-spacer"]}></span>
            {userId !== "anonymous" && (
              <button
                className={playerTheme["note-btn"]}
                style={{
                  backgroundColor: isSidePanelOpen ? "#fff" : "transparent",
                  color: isSidePanelOpen ? "#000" : "#fff",
                }}
                onClick={handleNoteBtn}
              >
                My Notes
              </button>
            )}
            <media-playback-rate-button
              style={{ alignSelf: "center" }}
              rates={`1,1.25,1.5,1.75,2`}
            ></media-playback-rate-button>
            <media-fullscreen-button onClick={handleFullScreen}>
              {/* <svg slot="enter"></svg> */}
              {/* <svg slot="exit"></svg> */}
            </media-fullscreen-button>
            {/* <media-pip-button></media-pip-button> */}
            {/* <media-current-time-display></media-current-time-display>
          <media-duration-display></media-duration-display>
          <media-captions-button></media-captions-button> */}
          </media-control-bar>
          <media-control-bar
            class="mobile"
            style={{ background: "linear-gradient(180deg, transparent, #000)" }}
          >
            {/* slot="top-chrome" className="mobile" slot="top-chrome" */}
            <media-mute-button></media-mute-button>
            <media-volume-range></media-volume-range>
            <media-time-range
              class={`${!showBookmarkBar ? "" : "d-none"}`}
            ></media-time-range>
            <media-time-display
              show-duration
              // remaining
              class={`${!showBookmarkBar ? "" : "d-none"}`}
            ></media-time-display>
            {/* <span className={playerTheme["control-spacer"]}></span> */}
            {/* <button
            className={playerTheme["note-btn"]}
            style={{
              backgroundColor: isSidePanelOpen ? "#fff" : "transparent",
              color: isSidePanelOpen ? "#000" : "#fff",
            }}
            onClick={handleNoteBtn}
          >
            Notes
          </button> */}
            <media-playback-rate-button
              rates={`1,1.25,1.5,1.75,2`}
            ></media-playback-rate-button>
            {/* <media-pip-button></media-pip-button> */}
            <media-fullscreen-button
              onClick={handleFullScreenButton}
            ></media-fullscreen-button>
            {/* <media-current-time-display></media-current-time-display>
          <media-duration-display></media-duration-display>
          <media-captions-button></media-captions-button> */}
          </media-control-bar>
        </>
      ) : theme === EVENT_THEME ? (
        <>
          <media-control-bar class="desktop">
            <div className={`w-100 ${playerTheme["event-container"]}`}>
              <span className="f-left">
                <media-mute-button></media-mute-button>
                <media-volume-range></media-volume-range>
              </span>
              {/* <span className={playerTheme["event-play-btn"]}>
              <media-play-button></media-play-button>
            </span> */}
              <span className={`f-right ${playerTheme["fullScreenContainer"]}`}>
                <div className={"live-text"}>
                  <span className="liveDot"></span> Live
                </div>
                <media-fullscreen-button onClick={handleFullScreen}>
                  {/* <svg slot="enter"></svg> */}
                  {/* <svg slot="exit"></svg> */}
                </media-fullscreen-button>
              </span>
            </div>
            {/* <span className={playerTheme["control-spacer"]}></span> */}
            {/* <span className={playerTheme["control-spacer"]}></span> */}
          </media-control-bar>
          <media-control-bar
            class="mobile"
            style={{ background: "linear-gradient(180deg, transparent, #000)" }}
          >
            <media-mute-button></media-mute-button>
            <media-volume-range></media-volume-range>
            <span className={playerTheme["control-spacer"]}></span>
            <div className={playerTheme["fullScreenContainerMobile"]}>
              <div className={"live-text"} style={{ paddingRight: "1rem" }}>
                <span className="liveDot"></span> Live
              </div>

              <media-fullscreen-button
                onClick={handleFullScreenButton}
              ></media-fullscreen-button>
            </div>
          </media-control-bar>
        </>
      ) : theme === MANKIND_STANDALONE_THEME ? (
        <>
          <media-control-bar class="desktop">
            <media-play-button></media-play-button>
            <media-time-range></media-time-range>
            <media-mute-button></media-mute-button>
            <media-volume-range></media-volume-range>
            <media-fullscreen-button></media-fullscreen-button>
          </media-control-bar>
          <media-control-bar class="mobile">
            <media-control-bar class="w-100">
              <media-time-range></media-time-range>
            </media-control-bar>
            <span className="f-left">
              <media-play-button></media-play-button>
            </span>
            <span className="f-right">
              <media-mute-button></media-mute-button>
              <media-volume-range></media-volume-range>
              <media-fullscreen-button></media-fullscreen-button>
            </span>
          </media-control-bar>
        </>
      ) : null}
    </media-controller>
  );
};

export default memo(MuxVideoPlayer);
