import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const getVideos = async () => {
  try {
    let docref = collection(db, "wdd2");
    let data = await getDocs(docref);
    if (!data) {
      let err = {
        message: "no videos Found",
      };
      throw err;
    }
    data = data.docs.map((doc) => doc.data());
    return data;
  } catch (err) {
    console.log(err);
  }
};

const get = {
  getVideos,
};

export default get;
