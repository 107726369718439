import { useEffect, useState } from "react";
import "./App.css";
import Modal from "./Components/Modal";
import VideoManager from "./Ṃanagers/Videos";
import docflixLogo from "./Assets/images/logo.png";
import mankind from "./Assets/images/mankind.png";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [videosList, setVideosList] = useState(null);
  const [videoData, setVideoData] = useState(null);

  useEffect(() => {
    VideoManager._get
      .getVideos()
      .then((res) => {
        console.log(res);
        setVideosList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClick = (video) => {
    setShowModal(true);
    setVideoData(video);
  };

  return (
    <div className="App">
      <div className="header">
        <div className="headerLogo">
          <img src={docflixLogo} alt="logo" />
          EDUCATION TO PROTECT TOMORROW
        </div>
        <img src={mankind} alt="logo" />
      </div>
      <div className="videosWrapper">
        {videosList &&
          videosList.map((video, index) => {
            if (video.status === "disabled") {
              return (
                <div
                  key={index}
                  onClick={() => handleClick(video)}
                  className="video"
                >
                  <img
                    className="videoUrl"
                    src={
                      video.thumbnailUrl
                        ? video.thumbnailUrl
                        : `https://image.mux.com/${
                            video.playback_ids[0].id
                          }/thumbnail.jpg${
                            video.tokens &&
                            video.tokens.thumbnailToken &&
                            video.tokens.thumbnailToken !== ""
                              ? `?token=${video.tokens.thumbnailToken}`
                              : ``
                          }`
                    }
                    alt="mankind"
                  />
                </div>
              );
            }
          })}
      </div>
      {showModal && <Modal videoData={videoData} setShowModal={setShowModal} />}
      <div className="footer"></div>
    </div>
  );
}

export default App;
